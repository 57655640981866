<template>
	<div class="trialsee alertDialogPage">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="试乘试驾"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		<div v-for="v,index in imagslist" :key="index">
			<van-image v-if="v.type_list == 'image'" :src="serverUrl(v.images)" style="width: 100%;"  fit="contain" />
			<div class="concat_text" v-if="v.type_list == 'text'">{{ v.content }}</div>
			<div class="audio" v-if="v.type_list == 'audio'">
					<audio :src="serverUrl(v.audio_files)" controls="controls"></audio>
			</div>
			<div class="video" v-if="v.type_list == 'video'">
				<video
        ref="myVideo"
				controls
				autoplay
        :src="serverUrl(v.video_files)"
        class="video-box"
      ></video>
			</div>
		</div>
		
		<!-- 切换按钮 -->
		<div style="height: 144px;"></div>
		<div class="swbtn" v-if="condition == 1">
			<div @click="handFinish" class="swbtn3">
				完成体验
			</div>
		</div>
		
		<!-- 弹出框 -->
			<van-dialog
				v-model="show"
				title="完成体验"
				show-cancel-button
				@confirm="handFinishReq">
				<div class="finish__con">
					确定要结束本次试乘试驾吗？
				</div>
			</van-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			condition: 1,
			number: '',
			node_id: 0,
			node_key: 0,
			scsj_experience_point_ids:'',
			list: [],
			imagslist:[],
			show:false,
		};
	},
	mounted() {
		if (this.$route.query.number) this.number = this.$route.query.number
		this.getProcess()
	},
	methods: {
		// 上一页
		handPrev(e) {
			this.node_key--
			this.node_id = this.list.filter((v, k) => k == this.node_key)[0].scsj_experience_node_id
		},
		
		// 下一页
		handNext(e) {
			this.node_key++
			this.node_id = this.list.filter((v, k) => k == this.node_key)[0].scsj_experience_node_id
		},
		
		// 切换菜单
		handMenu(e, key) {
			this.node_id = e.scsj_experience_node_id
			this.node_key = key
		},
		
		// 切换内容
		handContent(e) {
			this.node_key = e.detail.current
			this.node_id = this.list.filter((v, k) => k == this.node_key)[0].scsj_experience_node_id
		},
		
		// 完成体验
		handFinish(e) {
			this.show = true
		},
		async handFinishReq(e) {
			const {data: result} = await this.$http.post('/api/scsj/finish_scsj_1_1', {
				number: this.number,
				condition: 2
			})
			if (result.code == 1) {
				this.$toast.success(result.msg)
				this.$router.go(-1)
			} else {
				this.$toast.fail(result.msg)
			}
		},
		
		// 获取体验点内容
		async getpointimgs(e) {
			const {data: res} = await this.$http.post('/api/scsj/get_scsj_point_content_1_5', {
				point_id: this.scsj_experience_point_ids
			})
			if (res.code == 1) {
				this.imagslist = res.data.res
			}
		},
		
		// 获取详情
		async getProcess(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_process_catalogue', {
				number: this.number
			})
			if (result.code == 1) {
				this.scsj_experience_point_ids = result.data.res.scsj_experience_point_ids
				this.getpointimgs()
				this.condition = result.data.condition
				result.data.res.experience_point.forEach(v => {
					if (this.node_id == 0) this.node_id = v.scsj_experience_node_id
					this.list.push(v)
				})
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.trialsee{
	padding-bottom: 160px;
}
.menus{
	display: flex;
	white-space: nowrap;
	height: 72px;
}
.menu{
	text-align: center;
	line-height: 72px;
	font-size: 36px;
	font-family: HYQiHeiY3-55;
	color: #F3F3F3;
	padding: 0 20px;
}
.menu--active{
	color: #F3C846;
	border-bottom: 1px solid #F3C846;
}
.opt{
	width: 100%;
	height: 208px;
	background: url(https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/bg_see.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	font-size: 46px;
	font-family: HYQiHeiY3-55;
	color: #F3F3F3;
	line-height: 68px;
	margin-top: 20px;
	display: flex;
	align-items: center;
	padding: 0 116px 0 40px;
	position: relative;
	&__btn{
		position: absolute;
		top: 88px;
		right: 40px;
		width: 36px;
		height: 32px;
	}
}
.swbtn{
	background: #191919;
	position: fixed;
	bottom: 0;
	padding: 22px 40px;
	box-sizing: border-box;
	padding-bottom: calc(constant(safe-area-inset-bottom) + 22px);
	padding-bottom: calc(env(safe-area-inset-bottom) + 22px);
	width: 100%;
	.swbtn3{
		background: #FA0037;
		height: 92px;
		font-size: 32px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
		text-align: center;
		line-height: 92px;
	}
}
.finish__con{
	font-family: HYQiHeiY3-45;
	padding: 20px 0 ;
	text-align: center;
}

.audio{
	text-align: center;
	padding: 20px;
	audio{
		width: 80%;
	}
}
.video{
	width: 100%;
	padding: 20px 0;
}
.video-box{
	height: 300px;
	width: 750px;
}
.concat_text{
	padding: 20px;
	font-family: HYQiHeiY3-45;
	color: #fff;
}
</style>
